import React, { useEffect, useState, useGlobal } from "reactn";
import { useParams } from 'react-router-dom'
import { Row, Col, Form, Input, Button, Switch, Card, message, DatePicker, InputNumber, Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { GroupPicker, SendNotificationInput, CompetitionQuestionsInput, LangPicker } from '../components';
import moment from 'moment';
import api from '../service/index'
import { Link } from 'react-router-dom';
import { useCallback } from "react";

const CompetitionDetail = (props) => {

  let params = useParams()
  let history = useHistory()
  let id = params.id !== "add" ? params.id : false;
  let parentId=params.parentId;
  let [langCms] = useGlobal("langCms"); 
  let [langCode] = useGlobal("langCode"); 
  let newRecord = {
    lang: langCode ? langCode : langCms[0].code,
    name: '',
    groups: [],
    startDate: new Date(),
    endDate: new Date(),
  }

  let [data, setData] = useState(id ? {} : newRecord);
  let [answers, setAnswers] = useState();
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false)

  let [modules] = useGlobal("modules");
  let [languagesCms] = useGlobal("languagesCms"); 

  let path = props.location.pathname.split('/')[1];
  let module
  if (modules !== null) {
    [module] = modules.filter(el => el._id === path);
  }

   
  useEffect(() => {
    if (modules && id) {
      setLoading(true)

      api.get("/rest/competitions/" + id).then(({ data: { result, result_message } }) => {
        setData(result);
        setLoading(false)
      });
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      api.get("/rest/competitions/countAnswers/" + id).then(({ data: { result, result_message } }) => {
        setAnswers(result);
        setLoading(false)
      });
    }
  }, [id]);


  let validate = useCallback(() => {
    let errors = {};

    if (data.lang === null)
      errors.lang = languagesCms.ENFORCED

    if (data.name == null || data.name.length === 0)
      errors.name = languagesCms.ENFORCED

    if (data.timeToAnswer === null || data.timeToAnswer < 1)
      errors.timeToAnswer = 'Zorunlu Alan! Lütfen 1 dan büyük bir değer giriniz.'

    if (data.startDate === null)
      errors.startDate = languagesCms.ENFORCED

    if (!data.timeToAnswer)
      errors.timeToAnswer = languagesCms.ENFORCED

    if (data.endDate === null)
      errors.endDate = languagesCms.ENFORCED

    if (!data.questions || data.questions.length < 1)
      errors.questions = 'Hiç soru girmediniz';
    else data.questions.forEach((q, i) => {
      if (q.name === null || q.name.length < 1)
        errors.questions = `Soru metni boş geçilemez (${i + 1}. soru)`;

      else if (q.type === 'Media') {
        if (q.choices.length < 1)
          errors.questions = `Media seçimli soru için yeterli seçenek girmediniz. (${i + 1}. soru)`;
      }
      else if (q.type === 'Selected') {
        if (!q.childType)
          errors.questions = 'Lütfen "Sonuçlu" Seçiminin Seçenek Tipini Seçiniz.'
        else if (q.choices.length < 2) {
          errors.questions = `Sonuçlu seçimi için yeterli soru seçeneği girmediniz (${i + 1}. soru)`;
        }
        else if (q.choices.filter(f => f.correctAnswer).length < 1)
          errors.questions = `Sonuçlu seçimi için doğru seçeneği girmediniz (${i + 1}. soru)`;
      }

      else if (q.type !== 'Free' && q.choices.length < 2)
        errors.questions = `Yeterli soru seçeneği girmediniz (${i + 1}. soru)`;

      else if (q.type !== 'Free') q.choices.forEach((c, j) => {
        if (c.name === null || c.name.length < 1)
          errors.questions = `Seçenek boş geçilemez (${i + 1}. soru - ${j + 1}. seçenek)`;
      });
    });
    errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true)
    let err = validate()
    if (err.hasError) {
      setErrors(err)
      window.scrollTo({ top: 20, behavior: 'smooth' });
    }
    else {
      if (id) {
        let findCorrectNon=true
        let questions=data.questions
        console.log(data)
        await Promise.all(questions.map(async (q)=>{
          let findCorrect=q.choices.find(c=>c.correctAnswer==true)
            if(!findCorrect){
              message.error(q.name+" sorusunun doğru cevabı işaretlenmemiştir.", 2);
              findCorrectNon=false
            }
        }))
        if(findCorrectNon){
        api.put("/rest/competitions/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/competitions')
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      }
      } else {
        data.parentId=parentId;
        let findCorrectNon=true
        let questions=data.questions
        await Promise.all(questions.map(async (q)=>{
          let findCorrect=q.choices.find(c=>c.correctAnswer==true)
            if(!findCorrect){
              message.error(q.name+" sorusunun doğru cevabı işaretlenmemiştir.", 2);
              findCorrectNon=false
            }
        }))
        if(findCorrectNon){
        api.post("/rest/competitions", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/competitions')
          } else {
            message.error(result_message.message, 2);
          }
        })
      }
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/competitions">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
          <Form layout="horizontal" size={"large"} onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.LANG}>
                  <LangPicker langCms={langCms} langCode={langCode} languagesCms={languagesCms} record={data} setRecord={setData} name="lang" />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label={languagesCms.GROUP} help={errors.groups} validateStatus={errors.active ? 'error' : 'success'}>
                  <GroupPicker languagesCms={languagesCms} record={data} setRecord={setData} name="groups" />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label={languagesCms.TITLE} required help={errors.name} validateStatus={errors.name ? 'error' : 'success'}>
                  <Input name="name" value={data.name} onChange={e => setData({ ...data, name: e.target.value })} />
                </Form.Item>
              </Col>

            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.NOTIFICATION} help={errors.sendNotification} validateStatus={errors.sendNotification ? 'error' : 'success'}>
                  <SendNotificationInput record={data} setRecord={setData} name="sendNotification" languagesCms={languagesCms} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.STATUS} help={errors.active} validateStatus={errors.active ? 'error' : 'success'}>
                  <Switch checked={data.active ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, active: v })} />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.QUESTION_REPLY} help={errors.timeToAnswer} validateStatus={errors.timeToAnswer ? 'error' : 'success'}>
                  <InputNumber name="timeToAnswer" min={0} value={data.timeToAnswer} onChange={v => setData({ ...data, timeToAnswer: v })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.START_DATE} required help={errors.startDate} validateStatus={errors.startDate ? 'error' : 'success'}>
                  <DatePicker defaultValue={() => moment(data.startDate)} onChange={v => setData({ ...data, startDate: v })} format='DD/MM/YYYY HH:mm' showTime={true} />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.END_DATE} required help={errors.endDate} validateStatus={errors.endDate ? 'error' : 'success'}>
                  <DatePicker defaultValue={() => moment(data.endDate)} onChange={v => setData({ ...data, endDate: v })} format='DD/MM/YYYY HH:mm' showTime={true} />
                </Form.Item>
              </Col>
            </Row>


            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label={languagesCms.QUESTIONS} required help={errors.questions} validateStatus={errors.questions ? 'error' : 'success'}>
                  {answers > 0 &&
                    <Alert type="info" message={languagesCms.SURVEY_MESSAGE} banner />
                  }
                  <CompetitionQuestionsInput languagesCms={languagesCms} name='questions' record={data} setRecord={setData} disabled={answers > 0 ? true : false} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button type="primary" disabled={loading} htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                </Form.Item>
              </Col>
            </Row>

          </Form>
        </Card>
      </div>
    </div>
  );
};

export default CompetitionDetail;
